import { employeeinfo } from "../commonDynmicForms/employee_info";
import { FillableBy } from "./form_enums";
import { getSignatures } from "../commonDynmicForms/signature";
import { table_fields} from "../commonDynmicForms/table_fields"
import { evaluationCheckList } from "../commonDynmicForms/evaluation_checklist";
import { constants } from "../constants";

export default {
    fillable_by : FillableBy.Both,
    title:
        "Spectrum Quantum Heart-Lung Machine Competency Checklist",
    sections: [
        // {...employeeinfo},
        {
            title: "General Knowledge & Training",
            type: "table",
            heads: constants.tableHeads,
            rows: [
                "<b>Instruction(s) for Use Availability</b><br/>1. Can locate and reference the manufacturer’s Instructions for Use (IFU).<br/>2.  Understands how to access digital/printed manuals and quick reference guides",
                "<b>E-Learning Enrollment & Completion</b><br/>1. Completed manufacturer’s online training and certification modules.<br/>2. Passed any required knowledge assessments or quizzes."
            ].map((item) => ([{ text: item, type: 'text' }, ...table_fields])),
        },
        {
            title: "Equipment Familiarization & Setup",
            type: "table",
            heads: constants.tableHeads,
            rows: [
                "<b>Can identify and explain all key components of the Spectrum Quantum Heart-Lung Machine.</b><br/>1. Understands power supply requirements, battery backup function, and cable management.<br/>2. Demonstrates proper assembly and disassembly of essential components.",
                "<b>Sterile Technologies</b><br/>1. Understands the importance of maintaining a sterile field.<br/>2. Demonstrates correct handling of sterile tubing and connectors.<br/>3. Verifies sterility and proper priming of circuits before use.",
                "<b>Gauge Bar/Diagnostics/Capture-Sync</b><br/>1. Correctly interprets pressure, temperature, and flow readings.<br/>2. Verifies functionality of real-time diagnostics and alarm logging.<br/>3. Synchronizes data capture with electronic perfusion records (if applicable).",
                "<b>System Settings</b><br/>1. Configures patient-specific settings, including RPM, flow rates, and occlusion settings.<br/>2. Adjusts system parameters as per procedural requirements.<br/>3. Verifies settings before and during cardiopulmonary bypass (CPB).",
                "<b>Pump Manager</b><br/>1. Operates the pump management system effectively.<br/>2. Adjusts flow and RPM settings safely and efficiently.<br/> 3. Monitors pump performance and identifies potential issues.",
                "<b>Pump Interventions (Bubble Detection, Level, Pressure Monitoring)</b><br/>1. Demonstrates competency in air bubble detection and intervention.<br/>2. Correctly responds to level sensor alarms and adjusts as needed.<br/>3. Monitors pressure readings and makes necessary modifications.",
            ].map((item) => ([{ text: item, type: 'text' }, ...table_fields])),
        },
        {
            title: "Advanced Features & Safety Protocols",
            type: "table",
            heads: constants.tableHeads,
            rows: [
                "<b>Cardioplegia and Interlock Safeties</b><br/>1. Understands cardioplegia delivery system and safety interlocks.<br/>2. Configures and verifies proper function before CPB initiation.,<br/>3. Responds appropriately to alarms and flow interruptions.",
                "<b>Smart Modes</b><br/>1. Engages and utilizes Smart Modes for automated adjustments.<br/>2. Explains how different Smart Modes impact perfusion management.<br/>3. Adjusts settings based on patient needs and procedural requirements.",
                "<b>VIPER and Toolbox Applications</b><br/>1. Accesses and navigates VIPER<b>(Variable Input Patient Electronic Record)</b>.<br/>2. Uses Toolbox Applications for troubleshooting and optimizing performance.<br/>3. Logs and retrieves perfusion data from the machine.",
                "<b>Device Connectivity</b><br/>1. Establishes connectivity with external monitoring systems.<br/>2. Integrates with Electronic Medical Records (EMR) if applicable.<br/>3. Troubleshoots common connectivity issues.",
                "<b>Administrator Mode (Superusers Only)</b><br/>1. Accesses advanced system settings and configurations.<br/>2. Performs software updates and calibrations as needed.<br/>3. Assigns user access levels and manages system security.",
            ].map((item) => ([{ text: item, type: 'text' }, ...table_fields])),
        },
        {
            title: "Clinical Application & Performance",
            type: "table",
            heads: constants.tableHeads,
            rows: [
                "<b> Trainee Observation</b><br/>1. Shadowed a trained perfusionist during machine setup and CPB initiation.<br/>2. Asked questions and demonstrated understanding of key functions.",
                "<b>Clinical Case(s) Performed</b><br/>1. Successfully operated the Spectrum Quantum during live CPB cases.<br/>2. Adjusted parameters in response to intraoperative conditions.<br/>3. Worked effectively as part of the surgical team.",
                "<b>Emergency Procedures</b><br/>1. Responds effectively to power failure and battery backup activation.<br/>2.Demonstrates proper protocol for pump failure and manual backup<br/>3. Handles air embolism and circuit rupture scenarios.<br/>4. Implements contingency plans for oxygenator failure.",
            ].map((item) => ([{ text: item, type: 'text' }, ...table_fields])),
        },
        {...evaluationCheckList},
        {
            title: "Signatures",
            type: "signature",
            fields: getSignatures(FillableBy.Both),
        },
    ],
}